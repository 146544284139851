import { ZeroComponent } from './ZeroComponent'

class Toast extends ZeroComponent {
    constructor() {
        super().attachShadow({mode: 'open'}).innerHTML = this.render()

        this.setState({
            msg: ''
        })

        this.wire()
    }

    static get observedAttributes() {
        return ['text']
    }

    attributeChangedCallback(name, oldVal, newVal) {
        if(name === 'text' && newVal) {
            this.setState({
                msg: this.getAttribute('text')
            })
        }
    }

    wire() {
        this.select('[data-toast]').addEventListener('click', () => {
            if(!this.hasAttribute('data-event')) return
            this.dispatchEvent(new CustomEvent(this.dataset.event, { bubbles: true }))
        })
    }

    render() {
        return String.raw  `
            <style>
                @keyframes show {
                    0% {
                        opacity: 0;
                        transform: translateY(3rem);
                    }

                    100% {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .toast {
                    background-color: var(--background);
                    border: 0.1rem solid white;
                    border-radius: 1rem;
                    color: var(--content);
                    cursor: default;
                    font-size: var(--txt-sm);
                    font-weight: 500;
                    inset: auto auto 2rem 2rem;
                    max-width: 24rem;
                    position: fixed;
                    z-index: 10000;
                }

                [open] {
                    animation: show 300ms ease;
                    animation-fill-mode: both;
                }

                @media (hover: none) {
                    :host([no-mobile]) .toast {
                        display: none;
                    }
                }
            </style>

            <dialog class="toast" data-toast open data-bind="msg"></dialog>
        `
    }
}

customElements.define('zero-toast', Toast)