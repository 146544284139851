import { observeVIMode } from '../scripts/modules/observe-visually-impaired-mode'

export class ZeroComponent extends HTMLElement {
    state = {}
    _listeners = []

    constructor() {
        super()
    }

    connectedCallback() {
        this.wireInputs()

        // Set high-contrast mode
        observeVIMode({
            yes: () => {
                this.classList.add('visually-impaired')
            },

            no: () => {
                this.classList.remove('visually-impaired')
            }
        })
    }

    // Helper methods

    select(selector) {
        return this.shadowRoot ? this.shadowRoot.querySelector(selector) : this.querySelector(selector)
    }

    selectAll(selector) {
        return this.shadowRoot ? this.shadowRoot.querySelectorAll(selector) : this.querySelectorAll(selector)
    }

    set(obj, key, value) {
        const parts = key.split('.')
        let o = obj

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i]
            
            if (i + 1 === parts.length) {
                o[part] = value
                return
            }

            o[part] = o[part] ?? {}
            o = o[part]
        }
    }

    style(els, styles) {
        const elements = typeof els === 'object' ? els : [els]
        elements.forEach(elem => Object.assign(elem.style, styles))
    }

    html(markup, parentSelector, replaceContents = false) {
        const dom = new DOMParser().parseFromString(markup, 'text/html')
        const parent = (parentSelector === this.shadowRoot) ? this.shadowRoot : this.select(parentSelector)

        if(replaceContents) parent.innerHTML = ''

        parent.append(dom.body.firstChild)
    }

    // Simple Event Binding (e.g. data-event="click.once.prevent:funcName('single prop')")

    wireEventBinds() {
        const elems = this.selectAll('[data-event]')

        elems.forEach(elem => {
            const parseData = elem.dataset.event.split(':')
            const eventDetail = {
                name: parseData[0].replace(/\..*/, ''),
                prop: (parseData[1].match(/\(/)) ? parseData[1].match(/\((.*?)\)/)[1].replace(/\'/g, `"`) : null,
                func: parseData[1].replace(/\(.*/, ''),
                modifiers: (parseData[0].match(/\./)) ? parseData[0].split('.') : ''
            }

            elem.addEventListener(eventDetail.name, (event) => {
                const fn = this[eventDetail.func].bind(this)

                // Prevent Default
                if(eventDetail.modifiers.includes('prevent')) event.preventDefault()

                if(typeof fn === 'function') fn(event, elem, JSON.parse(eventDetail.prop))
            }, { 
                // Options
                once: eventDetail.modifiers.includes('once') ,
                capture: eventDetail.modifiers.includes('capture')
            })
        })
    }

    // Simple Data Binding (e.g. data-bind="prop" or data-bind="prop.nestedProp")

    wireInputs() {
        const inputs = [...this.selectAll(`[data-bind]`)]

        inputs.forEach(node => {
            const dataProp = node.dataset.bind
            
            node.addEventListener('change', () => {
                let obj = {}

                if(dataProp.includes('.')) {
                    const arr = [[dataProp, node.value]]
                    const obj = arr.reduce((obj, [key, value]) => {
                        this.set(obj, key, value)
                        return obj
                    }, {})

                    this.setState(obj)
                    return
                }

                if(node.type === 'checkbox') {
                    obj[dataProp] = node.checked
                }

                if(node.type !== 'checkbox') {
                    obj[dataProp] = node.value
                }

                this.setState(obj)
                
            })
        })
    }

    updateBindings(prop, value = '') {
        const bindings = [...this.selectAll(`[data-bind$="${prop}"]`)]

        bindings.forEach(node => {
            const dataProp = node.dataset.bind
            const bindProp = dataProp.includes(':') ? dataProp.split(':').shift() : false
            const bindValue = dataProp.includes('.') ? dataProp.split('.').slice(1).reduce((obj, p) => obj[p], value) : value
            const target = [...this.selectAll(node.tagName)].find(el => el === node)

            if(bindProp) {
                target.dataset[bindProp] = (this.isArray(bindValue) || this.isObject(bindValue)) ? JSON.stringify(bindValue) : bindValue.toString()
                return
            }

            if(node.tagName === 'SELECT') {
                target.querySelectorAll('option').forEach(node => node.removeAttribute('selected'))
                target.querySelector(`[value="${bindValue}"]`).setAttribute('selected', true)
                return
            }

            if(node.type === 'checkbox') {
                node.checked = value
                return
            }

            target.textContent = bindValue.toString()
            target.value = bindValue.toString()
        })
    }

    setState(newState) {
        Object.entries(newState).forEach(([key, value]) => {
            this.state[key] = this.isObject(this.state[key]) && this.isObject(value) ? {...this.state[key], ...value} : value

            const bindKey = this.isObject(value) ? this.getBindKey(key, value) : key
            const bindKeys = this.isArray(bindKey) ? bindKey : [bindKey]

            bindKeys.forEach(key => {
                this.updateBindings(key, value)
            })
        })

        this.notify(this.state)
    }

    clearState() {
        this.state = {}
        this.notify(this.state)
    }

    getBindKey(key, obj) {
        return Object.keys(obj).map(k => this.isObject(obj[k]) ? `${key}.${this.getBindKey(k, obj[k])}` : `${key}.${k}`);
    }

    isArray(arr) {
        return Array.isArray(arr);
    }

    isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    }

    // Observers

    notify() {
        this._listeners.forEach((listener) => listener(this.state))
    }

    subscribe(listener) {
        this._listeners.push(listener)
    }
}